/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";

import Header from "./header";
import Footer from "./footer";

import "@fortawesome/fontawesome-svg-core/styles.css";
// import { config } from "@fortawesome/fontawesome-svg-core";

import "./layout.scss";

import CookieConsent from "react-cookie-consent";

export default ({ children }) => (
  <>
    <CookieConsent
      location="bottom"
      buttonText="承認"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
    >
      このサイトはクッキーを使用しております。🍪
    </CookieConsent>
    <Header />
    {children}
    <Footer />
  </>
);
