// フッター

import {Link} from 'gatsby'
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
	faFacebookSquare,
	faInstagram,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons'

const Footer = ({data}) => (
	<>
		<footer>
			<div className='footerContainer'>
				<Link to={`/`}>
					<h2>相氣一進流</h2>
				</Link>
				<div className='footerNav'>
					<ul>
						<li>
							<Link to={`/`}>HOME</Link>
						</li>
						<li>
							<Link to={`/about`}>稽古内容</Link>
						</li>
						<li>
							<Link to={`/bloglist`}>ブログ</Link>
						</li>
						<li>
							<Link to={`/contact`}>お問い合せ</Link>
						</li>
						<li>
							<Link to={`/privacy`}>プライバシーポリシー</Link>
						</li>
						<li>
							<Link to={`https://en.aikiishinryu.jp/`}>English page</Link>
						</li>
					</ul>
					<ul className='sns'>
						<li>
							<a href='https://www.facebook.com/aikijutu/'>
								<FontAwesomeIcon
									icon={faFacebookSquare}
									style={{color: '#fff'}}
								/>
							</a>
						</li>
						<li>
							<a href='https://www.instagram.com/aiki_ishinryu_jiujitsu/'>
								<FontAwesomeIcon icon={faInstagram} style={{color: '#fff'}} />
							</a>
						</li>
						<li>
							<a href='https://www.youtube.com/channel/UC5L5ro8sDa7048WRFhJ4qTQ'>
								<FontAwesomeIcon icon={faYoutube} style={{color: '#fff'}} />
							</a>
						</li>
					</ul>
				</div>
				<div className='copyright'>
					<p>Copyright © 相氣一進流 All Rights Reserved.</p>
					<p>
						Built by <Link to={`https://github.com/yuyak97`}>Yuyak</Link>
					</p>
				</div>
			</div>
		</footer>
	</>
)

export default Footer
