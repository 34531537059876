import { Link } from "gatsby";
import React, { useState } from "react";
// import Img from "gatsby-image";

const Header = ({ data }) => {
  const [activeMenu, setActiveMenu] = useState(true);

  const activateSpMenu = () => setActiveMenu(!activeMenu);

  const spMenu = "";
  const spMenuActive = "";

  return (
    <header>
      <div className="site">
        <Link to={`/`}>
          <img src="/logo.png" alt="相氣一進流" className="logo" />
        </Link>
      </div>

      <div className="pcMenu">
        <ul>
          <li>
            <Link to={`/`}>HOME</Link>
          </li>
          <li>
            <Link to={`/about`}>稽古内容</Link>
          </li>
          <li>
            <Link to={`/bloglist`}>ブログ</Link>
          </li>
          <li>
            <Link to={`/contact`}>お問い合せ</Link>
          </li>
        </ul>
      </div>
      <div className="hamburger" onClick={activateSpMenu}>
        <span></span>
      </div>
      <div className={activeMenu ? "spMenu" : "spMenuActive"}>
        <ul>
          <Link to={`/`} onClick={activateSpMenu}>
            <li>HOME</li>
          </Link>
          <Link to={`/about`} onClick={activateSpMenu}>
            <li>稽古内容</li>
          </Link>
          <Link to={`/bloglist`} onClick={activateSpMenu}>
            <li>ブログ</li>
          </Link>
          <Link to={`/contact`}>
            <li>お問い合せ</li>
          </Link>
          <li className="close" onClick={activateSpMenu}>
            <span>閉じる</span>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
